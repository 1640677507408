






















import { Vue, Component, Ref } from 'vue-property-decorator'
import { checkPassword } from '@/utils/index'
import { Form } from 'element-ui'
import { resetPassword } from '@/api/user_api';
import { UserStoreModule } from '@/store/UserModule';
import { LayoutStoreModule } from '@/store/LayoutModule';
@Component
export default class UserResetPassword extends Vue {
  @Ref('myForm') myForm!: Form;
  modifing = false;
  form = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }
  formRules = {
    oldPassword: [
      { required: true, trigger: 'blur', message: this.$t('pleaseInput') }
    ],
    newPassword: [
      { required: true, trigger: 'blur', message: this.$t('pleaseInput') },
      { validator: this.validateNewPwd, trigger: 'blur'}
    ],
    confirmNewPassword: [
      { required: true, trigger: 'blur', message: this.$t('pleaseInput') },
      { validator: this.validateCfmNewPwd, trigger: 'blur'},
    ]
  }
  validateNewPwd(rule: any, value: string, callback: Function){
    if(!checkPassword(value)){
      callback(new Error(this.$t('register_login_tip') + ''));
      return;
    }
    callback();
  }
  validateCfmNewPwd(rule: any, value: string, callback: Function){
    if(value !== this.form.newPassword){
      callback(new Error(this.$t('two_password_not_equal') + ''));
      return;
    }
    callback();
  }
  resetValidate(str: string){
    this.myForm.clearValidate(str)
  }
  async submit(){
    if(this.modifing) return;
    try{
      await this.myForm.validate();
      this.modifing = true;
      const ret = await resetPassword({
        newLoginPs: this.form.newPassword,
        loginPs: this.form.oldPassword,
        id: UserStoreModule.userInfo.id,
      })
      if(ret.data.code == 1003) {
        this.myForm.resetFields();
        this.$message.success(this.$t('sthSuccess', {sth: this.$t('changePassword')})+'');
        LayoutStoreModule.removeNavItem(LayoutStoreModule.navItemActive);
      }
    } catch(e){
    }
    this.modifing = false;
  }
}
